import React, { Component } from 'react';
import PageHeader from '../components/PageHeader';
import SEO from '../components/SEO';
import ScreenshotComparison from '../components/ScreenshotComparison';
import Layout from '../layout';

class Page extends Component {
  render() {
    return (
      <Layout>
        <SEO title="Screenshot comparison" keywords={['']} pathname={this.props.location.pathname} />
        <PageHeader>
          <h1 style={{ maxWidth: '62rem' }}>Screenshots that just look right</h1>
          <h2>
            Where other tools make full page responsive screenshots that are broken and incorrect, Polypane is the only
            tool that makes full page screenshots that actually look like what you see on the screen when you scroll a
            page.
          </h2>
        </PageHeader>
        <ScreenshotComparison />
      </Layout>
    );
  }
}

export default Page;
