import { css } from 'astroturf';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';
import { Camera, ZoomIn } from 'react-bytesize-icons';
import ReactTooltip from 'react-tooltip';
import CTA from './CTA-small';

const styles = css`
  .screenshotComparison {
    & h2 {
      text-align: center;
      margin-bottom: 1rem;
    }
    & p.intro {
      margin-bottom: 3rem;
    }
  }
  .sideBySide {
    position: relative;
    width: 100%;
    overflow-x: auto;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    text-align: center;
    padding-bottom: 2rem;

    & > div {
      position: relative;
      flex: 1;

      & > div {
        max-width: 100%;
        margin: 0 4px;
      }
    }

    & p {
      line-height: 26px;
      margin-bottom: 2rem;
      font-weight: 600;
    }

    & .polypane,
    & .chrome,
    & .firefox,
    & .fullpage {
      box-shadow: 0 4px 4px rgba(20, 40, 50, 0.05), 0 1px 10px rgba(20, 40, 50, 0.05),
        0 20px 30px rgba(20, 40, 50, 0.08);
      border-radius: 3px;
    }
  }

  .selector {
    margin: 0 0 2rem;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;

    & button {
      pointer-events: all;
      margin: 0;
      font-size: 16px;
      font-weight: 600;
      background: rgba(255, 255, 255, 0.75);
      color: #107db5;
      border: 1px solid #107db5;
      padding: 0 0.66rem;
      text-align: center;
      display: inline-block;
      text-decoration: none;
      line-height: 1.5;
      transition: all 0.25s ease-in-out;
      overflow: hidden;

      &.active {
        color: #fff;
        background: #107db5;
      }

      &::after {
        position: absolute;
        left: 0px;
        bottom: 0px;
        content: ' ';
        width: 0;
        height: 100%;
        background: #107db5;
        opacity: 0.15;
        transition: 0.25s ease-out width;
        border-radius: 8px;
      }
    }

    & li:first-child button {
      border-radius: 8px 0 0 8px;
      border-right: 0;
    }
    & li:last-child button {
      border-radius: 0 8px 8px 0;
      border-left: 0;
    }
  }

  .payoff {
    text-align: center;
    margin-bottom: 4rem;
  }

  .tip {
    margin: 0;
    background: none;
    border: 0;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    color: #c47373;
    position: absolute;

    & svg {
      filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
    }
  }

  .chromeTip1 {
    left: calc(50% - 16px);
    top: 200px;
  }
  .chromeTip2 {
    left: 73%;
    top: 760px;
  }

  .chromeTip3 {
    left: 67%;
    top: 980px;
  }

  .chromeTip4 {
    left: 25%;
    top: 1820px;
  }
  .chromeTip5 {
    left: 73%;
    top: 2800px;
  }
  .chromeTip6 {
    left: 5%;
    top: 2840px;
  }

  .firefoxTip1 {
    left: calc(50% - 16px);
    top: 580px;
  }
  .firefoxTip2 {
    left: calc(50% - 16px);
    top: 2590px;
  }
  .firefoxTip3 {
    left: 67%;
    top: 2735px;
  }

  .fullpageTip1 {
    left: calc(50% - 16px);
    top: 180px;
  }
  .fullpageTip2 {
    left: 61%;
    top: 695px;
  }
`;

const Tip = (props) => (
  <button
    type="button"
    data-tip={props.text}
    data-place="left"
    data-type="error"
    className={[styles.tip, props.tipClass].join(' ')}
  >
    <ZoomIn />
  </button>
);

export default () => {
  const [browser, setBrowser] = useState('Chrome');

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [browser]);

  return (
    <StaticQuery
      query={query}
      render={(data) => (
        <>
          <div className={[styles.screenshotComparison, 'widePage'].join(' ')}>
            <div className={styles.sideBySide}>
              <div className="polypane">
                <p>Compare a screenshot made in Polypane to: </p>
                <GatsbyImage
                  image={data.polypane.childImageSharp.gatsbyImageData}
                  className={styles.polypane}
                  alt="Polypane screenshot"
                  title="Polypane screenshot"
                />
              </div>
              <div className="other">
                <ul className={styles.selector}>
                  <li>
                    <button
                      type="button"
                      className={browser === 'Chrome' ? styles.active : null}
                      onClick={() => {
                        setBrowser('Chrome');
                      }}
                    >
                      Chrome
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className={browser === 'Firefox' ? styles.active : null}
                      onClick={() => {
                        setBrowser('Firefox');
                      }}
                    >
                      Firefox
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className={browser === 'Fullpagescreencapture.com' ? styles.active : null}
                      onClick={() => {
                        setBrowser('Fullpagescreencapture.com');
                      }}
                    >
                      Fullpagescreencapture.com
                    </button>
                  </li>
                </ul>
                <div className={styles.screens}>
                  {browser === 'Chrome' && (
                    <>
                      <GatsbyImage
                        image={data.chrome.childImageSharp.gatsbyImageData}
                        className={styles.chrome}
                        alt="Chrome screenshot"
                        title="Chrome screenshot"
                      />
                      <Tip tipClass={styles.chromeTip1} text="Too much space" />
                      <Tip tipClass={styles.chromeTip2} text="Animation is not yet finished" />
                      <Tip tipClass={styles.chromeTip3} text="Videos are not showing" />
                      <Tip tipClass={styles.chromeTip4} text="Image hasn't loaded" />
                      <Tip tipClass={styles.chromeTip5} text="Animation is not yet finished" />
                      <Tip tipClass={styles.chromeTip6} text="Footer is completely missing" />
                    </>
                  )}
                  {browser === 'Firefox' && (
                    <>
                      <GatsbyImage
                        image={data.firefox.childImageSharp.gatsbyImageData}
                        className={styles.firefox}
                        alt="Firefox screenshot"
                        title="Firefox screenshot"
                      />
                      <Tip tipClass={styles.firefoxTip1} text="None of the animated elements are visible" />
                      <Tip tipClass={styles.firefoxTip2} text="None of the animated elements are visible" />
                      <Tip tipClass={styles.firefoxTip3} text="Image hasn't loaded" />
                    </>
                  )}
                  {browser === 'Fullpagescreencapture.com' && (
                    <>
                      <GatsbyImage
                        image={data.fullpage.childImageSharp.gatsbyImageData}
                        className={styles.fullpage}
                        alt="Fullpagescreencapture.com screenshot"
                        title="Fullpagescreencapture.com screenshot"
                      />
                      <Tip tipClass={styles.fullpageTip1} text="Too much space" />
                      <Tip tipClass={styles.fullpageTip2} text="Videos are not showing" />
                    </>
                  )}
                </div>
              </div>
            </div>
            <ul className={styles.selector}>
              <li>
                <button
                  type="button"
                  className={browser === 'Chrome' ? styles.active : null}
                  onClick={() => {
                    setBrowser('Chrome');
                  }}
                >
                  Chrome
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className={browser === 'Firefox' ? styles.active : null}
                  onClick={() => {
                    setBrowser('Firefox');
                  }}
                >
                  Firefox
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className={browser === 'Fullpagescreencapture.com' ? styles.active : null}
                  onClick={() => {
                    setBrowser('Fullpagescreencapture.com');
                  }}
                >
                  Fullpagescreencapture.com
                </button>
              </li>
            </ul>
            <div className={styles.payoff}>
              <h2>
                <Camera width={48} height={48} />
                <br />
                Nothing beats the full page screenshots that Polypane makes.
              </h2>
              <p>Get started right away with our free trial:</p>
              <CTA smallpadding text="Export the best screenshots with one click" />
            </div>
          </div>
        </>
      )}
    />
  );
};

export const query = graphql`
  query screenshotComparisonQuery {
    polypane: file(relativePath: { eq: "images/screenshots/polypane.png" }) {
      childImageSharp {
        gatsbyImageData(width: 440, quality: 90, layout: FIXED)
      }
    }
    chrome: file(relativePath: { eq: "images/screenshots/chrome.png" }) {
      childImageSharp {
        gatsbyImageData(width: 440, quality: 90, layout: FIXED)
      }
    }
    firefox: file(relativePath: { eq: "images/screenshots/firefox.png" }) {
      childImageSharp {
        gatsbyImageData(width: 440, quality: 90, layout: FIXED)
      }
    }
    fullpage: file(relativePath: { eq: "images/screenshots/fullpagescreencapture.com.png" }) {
      childImageSharp {
        gatsbyImageData(width: 440, quality: 90, layout: FIXED)
      }
    }
  }
`;
